import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Button from "react-bootstrap/Button";
import SeoPages from "../wp-content/seo";
import { normalizeUrl } from "../utils/urlHelper.js";

const BioTemplate = ({ data: { bio, site, m1Img, m2Img } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const m1Image = m1Img.localFile.childImageSharp.gatsbyImageData;
	const m2Image = m2Img.localFile.childImageSharp.gatsbyImageData;
	const seoData = SeoPages.find(
		(page) => normalizeUrl(page.path) === normalizeUrl(bio.uri)
	);
	const seo = {
		title: seoData?.title || `${bio.title} Biography | Dental House Pinner`,
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Biography",
				item: {
					url: `${siteUrl}/biography`,
					id: `${siteUrl}/biography`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: bio.title,
				item: {
					url: `${siteUrl}/biography/${bio.slug}`,
					id: `${siteUrl}/biography/${bio.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seo.title}
				description={bio.teamMemberFields.metaDescription}
				language="en"
				openGraph={{
					title: seo.title,
					description: `${bio.teamMemberFields.metaDescription}`,
					url: `${siteUrl}/biography/${bio.slug}`,
					type: "article",
					images: [
						{
							url: `${bio.teamMemberFields?.image.node.localFile.publicURL}`,
						},
					],
				}}
			/>
			<section className="bg-secondary">
				<Container fluid className="px-0 mx-0">
					<Row className="align-items-center justify-content-center">
						<Col className="mt-4 text-center" lg={5}>
							{" "}
							<GatsbyImage
								className="mw-100"
								image={
									bio.teamMemberFields?.image.node.localFile.childImageSharp
										.gatsbyImageData
								}
								alt={bio.teamMemberFields?.image.node.altText}
							/>
						</Col>
						<Col
							className="ps-lg-5 py-5 px-4 pe-lg-0 py-lg-0 text-center text-lg-start"
							lg={6}
						>
							{" "}
							<p className="text-text-secondary jost-bold fs-6">
								{bio.teamMemberFields.role}{" "}
								<span className="text-black ms-1">
									GDC: {bio.teamMemberFields.gdcNo}
								</span>
							</p>
							<h1 className=" display-4 pb-4">
								{bio.teamMemberFields.firstName} {bio.teamMemberFields.lastName}
							</h1>
							<p>{bio.teamMemberFields.description}</p>
							<Button
								className="text-white py-2 mt-3 px-5 w-100 w-md-auto"
								variant="accent"
								href="https://dental-house-practice-dental-house-pinner.dentr.net/"
								target="_blank"
								rel="noreferrer"
							>
								Book appointment
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					<Row>
						<Col>
							<div className="bio-content py-lg-6 py-5">
								{parse(bio.teamMemberFields.info)}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					{" "}
					<Row>
						<Col className="text-center mb-lg-10 mb-5">
							<p className="text-text-secondary jost-bold fs-6">
								{bio.teamMemberFields.role} APPOINTMENT
							</p>
							<h2 className=" display-4 pb-4">
								Book an appointment with
								<br className="d-none d-lg-block" />{" "}
								{bio.teamMemberFields.firstName} today
							</h2>

							<Button
								className="text-white py-2 px-5 w-100 w-md-auto"
								variant="accent"
								href="https://dental-house-practice-dental-house-pinner.dentr.net/"
								target="_blank"
								rel="noreferrer"
							>
								Book appointment
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="d-lg-none">
				<Container fluid className="px-0 mx-0">
					<Row>
						<Col xs={12}>
							<GatsbyImage
								className="w-100"
								image={m1Image}
								alt={m1Img?.altText}
							/>
						</Col>
						<Col xs={12}>
							<GatsbyImage
								className="w-100"
								image={m2Image}
								alt={m2Img?.altText}
							/>
						</Col>
					</Row>
				</Container>
			</section>
			<section
				style={{ height: "80vh" }}
				className="position-relative w-100 d-none d-lg-block"
			>
				<div className="h-100 position-absolute start-0 main-image-left">
					<GatsbyImage
						className="h-100 w-100"
						image={m1Image}
						alt={m1Img?.altText}
					/>
				</div>
				<div className="h-100 position-absolute end-0 main-image-right">
					<GatsbyImage
						className="h-100 w-100"
						image={m2Image}
						alt={m2Img?.altText}
					/>
				</div>
			</section>
		</Layout>
	);
};

export default BioTemplate;

export const pageQuery = graphql`
	query BioById($id: String!) {
		bio: wpTeamMember(id: { eq: $id }) {
			uri
			slug
			title
			teamMemberFields {
				image {
					node {
						altText
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				description
				firstName
				gdcNo
				hasBio
				info
				lastName
				role
				order
				metaDescription
			}
		}
		m1Img: wpMediaItem(title: { eq: "Dentist 21" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		m2Img: wpMediaItem(title: { eq: "Dentist 20" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
