const SeoPages = [
	{
		title: "Our Complaints Procedure | Dental House Pinner",
		path: `/policies/complaints-procedure`,
		description:
			"At Dental House Pinner, we take all our customer feedback seriously. Read our complaints procedure to find out how we deal with any claims we receive.",
	},
	{
		title: "Our Privacy Policy | Visit Us Today At Dental House Pinner",
		path: "/policies/privacy-cookie-policy",
		description:
			"Want to find out more about our privacy policy at Dental House Pinner? Click here to read more now.",
	},
	{
		path: "/team-members/shamik-desai",
		title: "Shamik Desai | Qualified Principle Dentist",
	},
	{
		path: "/team-members/puja-jethwa",
		title: "Puja Jethwa | Talented Facial Aesthetician & Oral Surgeon",
	},
	{
		path: "/team-members/roshwin-pereira",
		title: "Roshwin Pereira | Experienced Implant Surgeon",
	},
];

module.exports = SeoPages;
